import React from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useMedia from '../../../../hooks/useMedia';
import MapLocationMarker from '../../../global/modules/mapLocationMarker';
import EditCartButton from './EditCartButton';
import { useCartState } from '../../../../contexts/cart';
import { useFilterState } from '../../../cap';
import useCheckout from '../../../../hooks/useCheckout';
import GetMapPin20Icon from '../../../../resources/images/location-pin16.svg';
import './../howToGetOrder.scss';
import { MEDIA_TYPE } from '../../../global/constants';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';

const HowToGetOrderDetails = ({ storesData }) => {
    const [{ howToGetYourOrderDetails }, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }] = useFilterState();
    const mediaType = useMedia();
    const intl = useIntl();
    const userType = useCheckUser();
    const { handleEditInCart } = useCheckout();
    const isRoundTrip = !viewCart?.isInStorePickup;
    const isCreditUser = userType === USER_TYPE.CREDIT;

    const renderMapMarkers = isMobileView => {
        return (
            <div className={isMobileView ? `${'mapStoresBoxMobile'}` : `${'mapStoresBox'}`}>
                <MapLocationMarker
                    selectedLatLong={{
                        lat: howToGetYourOrderDetails?.selectedStoreDetails?.latitude,
                        long: howToGetYourOrderDetails?.selectedStoreDetails?.longitude
                    }}
                    defaultCenter={{
                        lat: howToGetYourOrderDetails?.selectedStoreDetails?.latitude,
                        long: howToGetYourOrderDetails?.selectedStoreDetails?.longitude
                    }}
                    selectedStoreValue={howToGetYourOrderDetails?.selectedStoreDetails?.pc}
                    storesData={storesData?.data[0]}
                    onStoreDetailsClick={true}
                    showMonochromaticMap={true}
                    zoomImage={true}
                    showDefaultZoomcontrol={false}
                    showCustomZoomControlDesktop={mediaType !== MEDIA_TYPE.mobile}
                    controlWrapperClass={'controlWrapperClass'}
                />
            </div>
        );
    };
    const getProjectDetails = () => {
        return (
            <>
                {projectDetails?.projectName?.toLowerCase()}
                <br />
                {projectDetails?.projectAddress1?.toLowerCase()}
                <br />
                {projectDetails?.selectedProjectCity?.toLowerCase()}, {projectDetails?.selectedProjectState}{' '}
                {projectDetails?.selectedProjectZip}
                <br />
            </>
        );
    };

    const getRoundTripCreditDetails = () => {
        return (
            <>
                <div className="pickupleft-block">
                    <GetMapPin20Icon className="map-pin-icon" />{' '}
                    <small className="pickupleft-storeTitle">
                        {' '}
                        {intl.formatMessage({ id: 'checkout:jobsite-address' })}{' '}
                    </small>
                </div>
                <address className="modal-map-storedetails-address">{getProjectDetails()}</address>
            </>
        );
    };

    const getRoundTripGuestAndCashDetails = () => {
        return (
            <>
                <div className="pickupleft-block">
                    <GetMapPin20Icon className="map-pin-icon" />{' '}
                    <small className="pickupleft-storeTitle">
                        {' '}
                        {intl.formatMessage({ id: 'checkout:delivery-address-heading' })}{' '}
                    </small>
                </div>
                <address className="modal-map-storedetails-address">
                    {viewCart?.location?.split(',')[0]?.toLowerCase()}
                    < br />
                    {viewCart?.jobSiteCity?.toLowerCase()},{' '}
                    {viewCart?.jobSiteState}{' '}
                    {viewCart?.jobSiteZip}
                    <br />
                </address>
            </>
        );
    };

    const getPickupStoreDetails = () => {
        return (
            <>
                <div className="pickupleft-block">
                    <GetMapPin20Icon className="map-pin-icon" />{' '}
                    <small className="pickupleft-storeTitle">{intl.formatMessage({ id: 'cap:pickup-store' })}</small>
                </div>
                <address className="modal-map-storedetails-address">
                    {intl.formatMessage({ id: 'store-details:branch' })}
                    {howToGetYourOrderDetails?.selectedStoreDetails?.pc}
                    <br />
                    {howToGetYourOrderDetails?.selectedStoreDetails?.street?.toLowerCase()}
                    <br />
                    {howToGetYourOrderDetails?.selectedStoreDetails?.city?.toLowerCase()},{' '}
                    {howToGetYourOrderDetails?.selectedStoreDetails?.state}{' '}
                    {howToGetYourOrderDetails?.selectedStoreDetails?.zip}
                    <br />
                </address>
            </>
        );
    };
    const getStoreDetails = () => {
        if (isRoundTrip) {
            if (isCreditUser) {
                return getRoundTripCreditDetails();
            } else {
                return getRoundTripGuestAndCashDetails();
            }
        } else {
            return getPickupStoreDetails();
        }
    };
    return (
        <div className="pickupSection__container">
            <div className="pickupSection__leftSection">
                <div className="pickupleft-topSection">
                    <h6 className="pickup-type" data-testid="pickup_instore_pickup">
                        {isRoundTrip
                            ? intl.formatMessage({ id: 'order-summary:round-trip-delivery' })
                            : intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                    </h6>
                    <EditCartButton
                        handleOnClick={handleEditInCart}
                        testId="edit_cart_button"
                        isShowEditIcon={mediaType !== MEDIA_TYPE.mobile}
                        customButtonAriaLabel={intl.formatMessage({ id: 'checkout:edit-in-cart-label' })}
                    />
                </div>
                <div className="pickupleft-midleSection">{getStoreDetails()}</div>
                <div className="pickupleft-bottomSection">
                    <div className="startDateSection">
                        <div data-testid={checkoutDatalocator.checkout_rentalsstart_label_testid}>
                            {intl.formatMessage({ id: 'checkout:start-date' })}
                        </div>
                        <div data-testid={checkoutDatalocator.checkout_pickUptime_field_testid} className="startDate">
                            {moment(startDate || startDate).format('MMM DD, YYYY')}
                        </div>
                    </div>
                    <div className="endDateSection">
                        <div data-testid={checkoutDatalocator.checkout_rentalsend_label_testid}>
                            {isCreditUser
                                ? intl.formatMessage({ id: 'checkout:estimated-end-date' })
                                : intl.formatMessage({ id: 'checkout:end-date' })}
                        </div>
                        <div data-testid={checkoutDatalocator.checkout_returnTime_field_testid} className="endDate">
                            {moment(endDate).format('MMM DD, YYYY')}
                        </div>
                    </div>
                </div>
                {isCreditUser && !isRoundTrip && (
                    <div className="pickupleft-jobsiteSection">
                        <small className="pickupleft-jobsiteTitle">
                            {intl.formatMessage({ id: 'checkout:jobsite-address' })}
                        </small>
                        <address className="jobsitedetails-address">{getProjectDetails()}</address>
                    </div>
                )}
            </div>
            <div className="pickupSection__rightSection">
                {/* map goes here */}
                {renderMapMarkers(false)}
            </div>
        </div>
    );
};
export default React.memo(HowToGetOrderDetails);
