import React from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../aem-core-components';
import { useCartState } from '../../contexts/cart';
import useMedia from '../../hooks/useMedia';
import { FACTORYSOURCE } from '../molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import OrderSummaryFactory from '../molecules/orderSummaryFactory/OrderSummaryFactory';
import RentalEquipmentView from './accessoriesAndAddons/rentalEquipment/RentalEquipmentView';
import './checkout.scss';
import { checkoutDatalocator } from './checkoutAndOrderSummary/dataLocators';
import CheckoutPageAlerts from './checkoutPageAlert';
import ElapsedTimeDateAlert from './elapsedTimeDateAlert';
import RentalReviewList from './RentalReviewList';
import Alert from '../global/atoms/alert/alert';
import AlertTriangleRed from '../../resources/images/alert-triangle-red.svg';
import { SCROLL_MARGIN_TOP_VALUE } from './constants';

const CheckoutStepsAndOrderSummary = ({
    cartItems,
    isRentalDatesEmpty,
    orderEstimates,
    renderSteps,
    setCurrentStep,
    formStep,
    currentStep,
    multipleErrors
}) => {
    const intl = useIntl();
    const [{ consumables, isLoading, isReservationClicked, loaders }, dispatch] = useCartState();
    const { isEstimatesLoading } = loaders || {};
    const mediaType = useMedia();

    const renderAddOns = () => {
        if (consumables?.showRentalEquipmentScreen) {
            return <RentalEquipmentView mediaType={mediaType} isOpen={consumables?.showRentalEquipmentScreen} />;
        }
        return <></>;
    };

    const renderOrderSummary = () => {
        return (
            <>
                {
                    !isEstimatesLoading && 
                    <>
                        <h4
                            className="block-checkout-title"
                            data-testid={checkoutDatalocator.checkout_OrderSummary_heading_testid}>
                            {intl.formatMessage({ id: 'cart-order-summary:title' })}
                        </h4>
                        <RentalReviewList
                            cartItems={cartItems}
                            isRentalDatesEmpty={isRentalDatesEmpty}
                            orderEstimates={orderEstimates}
                        />
                    </>
                }
                <OrderSummaryFactory
                    title={intl.formatMessage({ id: 'cart-order-summary:title' })}
                    source={FACTORYSOURCE.CHECKOUT}
                    formStep={formStep}
                    currentStep={currentStep}
                />
            </>
        );
    };

    return (
        <section className={`block-gradient block-checkout ${isLoading && 'clicks-disabled'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-7 block-checkout-left checkout-steps-container">
                        <h4 className="block-checkout-title" data-testid={checkoutDatalocator.checkout_heading_testid}>
                            {intl.formatMessage({ id: 'checkout:checkout' })}
                        </h4>
                        {multipleErrors?.length > 1 && (
                            <Alert
                                message={intl.formatMessage({ id: 'checkout:multiple-error-msg' })}
                                type="error"
                                icon={<AlertTriangleRed tabIndex={'-1'} aria-hidden={true} />}
                                multipleAnchorTagNames={multipleErrors}
                                customClass="multiple-errors"
                                scrollMarginTopVal={SCROLL_MARGIN_TOP_VALUE}
                                ariaLabel={intl.formatMessage({ id: 'checkout:multiple-error-msg' })}
                            />
                        )}
                        <p className="requiredFields">{intl.formatMessage({ id: 'checkout:required-fields' })}</p>
                        {renderSteps()}
                        {isLoading && isReservationClicked && (
                            <div className={'submitReservationLoader'}>
                                <LoadingIndicator />
                            </div>
                        )}
                    </div>
                    <div className="col-lg-5 col-md-12 block-checkout-right">{renderOrderSummary()}</div>
                </div>
            </div>
            <ElapsedTimeDateAlert handleStepChange={setCurrentStep} formStep={formStep} currentStep={currentStep} />
            <CheckoutPageAlerts handleStepChange={setCurrentStep} formStep={formStep} />
            {renderAddOns()}
        </section>
    );
};

export default CheckoutStepsAndOrderSummary;
