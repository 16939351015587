import React, { Suspense, memo, useEffect, useState } from 'react';
import {
    RESET_EDIT_ON_CHECKOUT_COMPLETE,
    SET_CART_CONTEXT_FIELDS,
    SET_CLICKED_MAKE_CHANGES,
    SET_CREDIT_NEWADDR_FLAG,
    SET_PAYMENT_TOKEN_DATA,
    SET_PUNCHOUT_USER_DATA,
    SET_SELECTED_PAYMENT_METHOD,
    SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER,
    SET_SOURCES_LOADING_FLAG,
    SET_YOUR_DETAILS
} from '../../aem-core-components/actions/constants';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { cookieValue } from '../../aem-core-components/utils/cookieUtils';
import isObjectEmpty from '../../aem-core-components/utils/isObjectEmpty';
import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce';
import { EVENT_PAGE_NAMES_CONFIG } from '../../constants/analyticsConstants/Page';
import { EVENT_P2P_NAMES_CONFIG } from '../../constants/analyticsConstants/ProcureToPay';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import { useCartState } from '../../contexts/cart';
import useAnalytics from '../../hooks/useAnalytics';
import useCheckLocationEmpty from '../../hooks/useCheckLocationEmpty';
import { useCheckAuthorityType, useCheckUser } from '../../hooks/useCheckUser';
import useCheckout from '../../hooks/useCheckout';
import { useDidMount } from '../../hooks/useDidMount';
import useMedia from '../../hooks/useMedia';
import config from '../App/config';
import { useFilterState } from '../cap';
import { SET_END_DATE, SET_START_DATE } from '../cap/constants';
import { useCartOperations } from '../cart/hooks/useCart';
import TransmitOrSaveMain from '../checkoutv2/transmitOrSave/TransmitOrSaveMain';
import { AUTHORITY_TYPE, SUCCESS } from '../global/constants';
import {
    checkIsEditQuoteFlow,
    getURLParams,
    isAccountBlocked,
    isAccountClosed,
    isPaymentSectionVisited
} from '../global/utils/commonUtils';
import { isValidString, logError } from '../global/utils/logger';
import { SET_TRANSMITTED_QUOTE_ID } from '../quotes/actionTypes/actionTypes';
import { useQuotesState } from '../quotes/context';
import QuoteTransmitModal from '../quotes/qouteTransmitModal/QuoteTransmitModal';
import CheckoutStepsAndOrderSummary from './CheckoutStepsAndOrderSummary';
import { getAddPaymentAPI, getTokenAPI } from './checkoutAndOrderSummary/api/getCheckoutAPIs';
import { FORM_STEP_GUEST, TRANSMIT_NOW } from './constants';
import './checkout.scss';
import { ENV_CONFIG } from '../../constants/envConfig';

const Fallback = memo(() => <span>loading</span>);
const LazySubmitReservationButton = React.lazy(() =>
    import(/* webpackMode: "eager" */ '../global/atoms/SubmitReservation')
);
const LazyCheckoutGuestUser = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutGuestUser'));
const LazyCheckoutCashUser = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutCashUser'));
const LazyCheckoutCreditUser = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutCreditUser'));
const LazyReservationConfirmation = React.lazy(() => import(/* webpackMode: "eager" */ './confirmation'));
const LazyPunchoutOrderConfirmation = React.lazy(() =>
    import(/* webpackMode: "eager" */ './punchoutOrderConfirmation')
);

const LazyQuoteConfirmation = React.lazy(() =>
    import(/* webpackMode: "eager" */ '../checkoutv2/transmitOrSave/QuoteConfirmation/QuoteConfirmation')
);

const CheckoutDotcom = () => {
    const [
        {
            cart,
            cartId,
            currentOffset,
            submitReservation,
            punchoutUserData,
            userAccount,
            optionalPlan,
            clickedMakeChanges,
            selectedTransmitOrSave,
            orderSummaryDetails,
            loaders
        },
        dispatch
    ] = useCartState();
    const { rentalSubtotal } = orderSummaryDetails;
    const { isEstimatesLoading } = loaders;
    const [{ viewCart, projectDetails, endDate, startDate }, filterDispatch] = useFilterState();
    const mediaType = useMedia();
    const [userState, { dispatch: userDispatch, getProjectsFromCookie }] = useUserContext();
    const { renderCheckoutWithoutValidations, fetchCartForCheckout } =
        useCartOperations();
    const authorityType = useCheckAuthorityType();
    const [currentOffSet, setCurrentOffSet] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [isStepForward, setIsStepForward] = useState(false);
    const [formStep, setFormStep] = useState({});
    const [multipleErrors, setMultipleErrors] = useState([]);
    const [{ isTransmitting, transmittedQuoteId, salesforceQuoteId }, quoteDispatch] = useQuotesState();
    const userType = useCheckUser();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const cartItems = cart?.availableCartItems;
    const {
        sendEventsForEcommercePage,
        sendEventsForPageUser,
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceCheckoutOption,
        sendEventsForEcommercePageData,
        sendEventsForEcommerceAction,
        sendEventsForP2PApplication
    } = useAnalyticsContext();
    const {
        refreshCart,
        checkoutFormStart,
        checkoutStepPayload,
        getFormStep,
        isAccessoriesAddonsAvailable,
        customBillingFieldsCall
    } = useCheckout();
    const { isCheckoutLoading, isUserProfileLoading, isProfileLoaded } = userState;
    const didMount = useDidMount();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();

    const [customBillingFields, setCustomBillingFields] = useState({});
    const [isCustomAccount, setIsCustomAccount] = useState(false);
    const projectInfoCookies = getProjectsFromCookie();
    const params = getURLParams();
    const [isCCLogin, setIsCCLogin] = useState(false);
    const [sixDigitLat, setSixDigitLat] = useState(0);
    const sessionStartDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
    const sessionEndDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
    const [
        {
            payloadEcommerceActionAnalytics,
            payloadEcommercePageData,
            payloadEcommerceLocationActionAnalytics,
            createPayloadTransmitQuotePageLoadActionAnalytics
        }
    ] = useAnalytics();
    const sucessfullyTransmittedQuoteId = sessionStorage.getItem(
        STORAGE_CONFIG.SESSION_STORAGE.SUCCESSFULLY_TRANSMITTED_QUOTE_ID
    );
    const checkoutDetails = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUTUSERDETAILS) || '{}');
    const [isCartFetched, setIsCartFetched] = useState(false);
    const isAEMEditingMode = ENV_CONFIG.IS_EDITOR_MODE;

    useEffect(() => {
        const checkoutShimmerComponent = document?.querySelector('.checkout-shimmer');
        const checkoutComponent = document?.querySelector('.checkout-container'); 
        const checkoutContainer = document?.querySelector('.checkout');
        if (params?.TRANSUCCESS) {
            if (checkoutShimmerComponent) {
                checkoutShimmerComponent.style.display = 'none';
            }
            if (checkoutComponent) {
                checkoutComponent.style.display = 'block';
                checkoutContainer.style.minHeight = '80vh';
            }
        } else if (isProfileLoaded && cart?.id && !isObjectEmpty(checkoutDetails)) {
            if (checkoutShimmerComponent) {
                checkoutShimmerComponent.style.display = 'none';
            }
            if (checkoutComponent) {
                checkoutComponent.style.display = 'block';
                checkoutContainer.style.minHeight = '80vh';
            }
        }
            
    }, [isProfileLoaded, cart?.id]);

    useEffect(() => {
        if (isObjectEmpty(checkoutDetails) && !isAEMEditingMode) {
            window.location.href = window.location.origin + config?.pagePaths?.cartPage;
        } else {
            const data = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.CARTCONTEXT));
            const cookieCart = cookieValue(STORAGE_CONFIG.COOKIES.CIF_CART);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST);
            if (
                isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREATEJOBSITE)) &&
                !isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
            ) {
                setCurrentStep(2);
            }
            if (data && cookieCart) {
                if (params.TRANSUCCESS === 'TRUE') {
                    dispatch({
                        type: SET_CART_CONTEXT_FIELDS,
                        cartContextData: { ...data[0] }
                    });
                    dispatch({ type: SET_CLICKED_MAKE_CHANGES, value: false });
                    dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: false });
                    setFormStep(JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.FORMSTEP)));
                    if (params.CUSTOM2 === 'TRUE') {
                        async function fetchAddPaymentAPI() {
                            try {
                                dispatch({ type: 'beginLoading' });
                                await getAddPaymentAPI(true);
                                dispatch({
                                    type: SET_SELECTED_PAYMENT_METHOD,
                                    key: 'selectedPaymentMethod',
                                    value: 0
                                });
                                setCurrentStep(
                                    parseInt(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PAYMENTSTEP))
                                );
                                dispatch({ type: 'endLoading' });
                            } catch (err) {
                                logError(err, false, 'fetchAddPaymentAPI');
                            }
                        }
                        fetchAddPaymentAPI();
                    }
                    if (params?.CUSTOM2 !== 'TRUE') {
                        async function fetchPaymentToken() {
                            try {
                                dispatch({ type: 'beginLoading' });
                                const tokenData = await getTokenAPI();
                                sendEventsForEcommerceCheckoutOption(5, {
                                    paymentMethod: tokenData?.data?.data?.ccBrand
                                });
                                sendEventsForUpdateVirtualPath(
                                    VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_PAYMENT,
                                    `/${
                                        localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) == 2
                                        ? VARIABLE_CONFIG.REGION.CANADA
                                        : VARIABLE_CONFIG.REGION.US
                                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CHECKOUT_PAYMENT}`
                                );
                                dispatch({ type: SET_PAYMENT_TOKEN_DATA, value: tokenData?.data?.data });
                                setCurrentStep(
                                    parseInt(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PAYMENTSTEP))
                                );
                                dispatch({ type: 'endLoading' });
                            } catch (err) {
                                logError(err, false, 'fetchPaymentToken');
                            }
                        }
                        fetchPaymentToken();
                    }
                }
                if (params.TRANSUCCESS !== 'TRUE' && isPaymentSectionVisited()) {
                    dispatch({
                        type: SET_CART_CONTEXT_FIELDS,
                        cartContextData: { ...data[0] }
                    });
                    dispatch({ type: SET_CLICKED_MAKE_CHANGES, value: false });
                    dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: false });
                    setFormStep(JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.FORMSTEP)));
                    setCurrentStep(parseInt(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PAYMENTSTEP)));
                }
            }
            if (
                !cookieCart &&
                window.location.pathname.includes('checkout') &&
                !window.location.href.includes('author') &&
                !window.location.href.includes('localhost')
            ) {
                window.location.href = config?.pagePaths?.homePage;
            }
            if (sucessfullyTransmittedQuoteId) {
                dispatch({ type: SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER, value: TRANSMIT_NOW });
                quoteDispatch({ type: SET_TRANSMITTED_QUOTE_ID, payload: sucessfullyTransmittedQuoteId });
            }
        }
    }, []);

    useEffect(() => {
        //if loggin user with cart loaded && cc login
        if (params?.SuccessfulLogin) {
            if (
                userType !== USER_TYPE.GUEST &&
                cart?.availableCartItems?.length > 0 &&
                !isUserProfileLoading &&
                !isCCLogin
            ) {
                if (userType === USER_TYPE.CREDIT && projectDetails?.selectedProjectLongitude) {
                    ///credit profile loaded
                    setIsCCLogin(Boolean(params?.SuccessfulLogin));
                } else if (viewCart?.lat) {
                    //cash profile loaded
                    setIsCCLogin(Boolean(params?.SuccessfulLogin));
                }
                // call the page load API if user logins form cart page
                // fn();
            }
        }
    }, [
        cart?.availableCartItems?.length,
        isUserProfileLoading,
        viewCart?.lat,
        userType,
        projectDetails?.selectedProjectLongitude
    ]);

    useEffect(() => {
        async function getData() {
            if (sessionStartDate && sessionEndDate) {
                filterDispatch({ type: SET_START_DATE, startDate: sessionStartDate });
                filterDispatch({ type: SET_END_DATE, endDate: sessionEndDate });
            }
            const userProfile = await JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE));
            const isCreditNewAddress = await JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
            );
            const yourDetails = (await localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.YOURDETAILS)) || '';
            const punchoutUserData = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.PUNCHOUTUSERDATA) || '';
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING))
            });
            dispatch({
                type: SET_CREDIT_NEWADDR_FLAG,
                isCreditNewAddress: Boolean(isCreditNewAddress)
            });
            userDispatch({
                type: 'setUserProfile',
                userProfile: userProfile || userState.userProfile
            });
            if (yourDetails) {
                dispatch({ type: SET_YOUR_DETAILS, guestUserDetails: JSON.parse(yourDetails) });
                setCurrentStep(FORM_STEP_GUEST.YOUR_DETAILS);
            }
            if (punchoutUserData) {
                dispatch({ type: SET_PUNCHOUT_USER_DATA, punchoutUserData: JSON.parse(punchoutUserData) });
            }
        }
        if (!isUserProfileLoading) {
            getData();
        }
    }, [isUserProfileLoading]);

    useEffect(() => {
        //if loggin user with cart loaded && cc login
        if (viewCart?.lat) {
            setSixDigitLat(Math.floor(Number(viewCart?.lat) * 1000000) / 1000000);
        } else {
            setSixDigitLat(0);
        }
    }, [viewCart?.lat]);

    useEffect(() => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        if (rentalSubtotal && !isEditQuoteFlow) {
            sendEventsForP2PApplication(
                EVENT_P2P_NAMES_CONFIG.P2P_CREATE_QUOTE_STARTED,
                createPayloadTransmitQuotePageLoadActionAnalytics()
            );
        }
    }, [rentalSubtotal]);

    useEffect(() => {
        if (didMount) {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ);
            }
        }
    }, [startDate, endDate]);

    useEffect(() => {
        const cartLoadFunction = () => {
            if (isProfileLoaded && cartId) {
                async function loadCart() {
                    try {
                        dispatch({ type: 'beginLoading' });
                        await fetchCartForCheckout();
                        setIsCartFetched(true);
                    } catch (err) {
                        logError(err, false, 'loadCart');
                        dispatch({ type: 'endLoading' });
                    }
                }
                if (isRentalDetailsAvailable() && startDate && endDate) {
                    loadCart();
                } else if (authorityType === AUTHORITY_TYPE.P2P && transmittedQuoteId) {
                    // For P2P users, if we have a transmitted quote ID, then it will show a confirmation screen rather than going to the cart page.
                    return;
                } else {
                    window.location.href = window.location.origin + config?.pagePaths?.cartPage;
                }
            }
        };
        cartLoadFunction();
    }, [isProfileLoaded, cartId]);

    useEffect(() => {
        const fetchCheckoutDetails = async () => {
            try {
                //explicit null check is added as isEstimatesLoading can be false
                if (isProfileLoaded && cart?.total_quantity > 0 && isCartFetched && (isEstimatesLoading || isEstimatesLoading == null)) {
                    if (!isPaymentSectionVisited()) {

                        await renderCheckoutWithoutValidations();   
                    }
                    dispatch({ type: 'endLoading' });
                }
            } catch (err) {
                logError(err, false, 'fetchCheckoutDetails');
                dispatch({ type: 'endLoading' });
            }
        };
        fetchCheckoutDetails();
    }, [isProfileLoaded, cart?.total_quantity, isCartFetched]);

    useEffect(() => {
        if (
            (!(currentStep > formStep?.HTGO) ||
                (currentStep > formStep?.HTGO && !isAccessoriesAddonsAvailable()) ||
                (currentStep > formStep?.HTGO && formStep?.ACCESSORIES_AND_ADDONS)) &&
            isCheckoutPageLoaded()
        ) {
            cart?.id && callEvents();
        }
    }, [currentStep, isUserProfileLoading, cart?.id]);

    useEffect(() => {
        if (isAccessoriesAddonsAvailable() && currentStep > formStep?.HTGO) {
            callEvents();
        }
    }, [formStep]);

    useEffect(() => {
        // cart empty issue fixed when products are empty in cart in checkout page
        // DOTC-19565
        //to avoid redirection in author mode
        if (!window.location.href.includes('author') && !window.location.href.includes('localhost')) {
            if (cart?.items?.length <= 0) {
                window.location.href = config?.pagePaths?.homePage;
                return;
            } else if (cart?.availableCartItems?.length <= 0) {
                window.location.href = config?.pagePaths?.cartPage;
                return;
            }
        }
        if (isProfileLoaded) {
            if (userType == USER_TYPE.CREDIT) {
                if (
                    isAccountClosed(userAccount) ||
                    (isAccountBlocked(userAccount) && !window.location.href.includes('author'))
                ) {
                    window.location.href =
                        window.location.origin + config.pagePaths.homePage + '?showDelinquentModal=true';
                }
            }
        }
    }, [cart?.availableCartItems, isProfileLoaded]);

    useEffect(() => {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CHECKOUT_CURRENT_STEP, currentStep); // need currentStep dependency
        if (isCheckoutPageLoaded()) {
            document.querySelector('.editable-body')?.removeEventListener(
                VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK,
                elem => {
                    checkoutFormStart(elem, formStep, currentStep);
                },
                true
            );
            document.querySelector('.editable-body')?.addEventListener(
                VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK,
                elem => {
                    checkoutFormStart(elem, formStep, currentStep);
                },
                true
            );
        }

        return () => {
            document.querySelector('.editable-body')?.removeEventListener(
                VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK,
                elem => {
                    checkoutFormStart(elem, formStep, currentStep);
                },
                true
            );
        };
    }, [isProfileLoaded, cart?.id, currentStep]);

    useEffect(() => {
        if (projectInfoCookies?.CurrentWynneAccount && isProfileLoaded) {
            customBillingFieldsCall(setCustomBillingFields, setIsCustomAccount);
        }
    }, [projectInfoCookies?.CurrentWynneAccount, isProfileLoaded]);

    useEffect(() => {
        if ((!isPaymentSectionVisited() && !params.TRANSUCCESS) || clickedMakeChanges) {
            getFormStep(currentStep, setFormStep, isCustomAccount);
        }
    }, [currentStep, userType, isCustomAccount, optionalPlan, userAccount?.accountNumber]);

    useEffect(() => {
        // this useEffect only impacts CC =>checkout login
        if (didMount) {
            if ((!isPaymentSectionVisited() || clickedMakeChanges) && isProfileLoaded) {
                refreshCart();
            }
        }
    }, [isCCLogin, endDate, projectDetails?.selectedProjectLongitude, isProfileLoaded]);

    useEffect(() => {
        if (
            !isPaymentSectionVisited() &&
            cart?.availableCartItems?.length > 0 &&
            currentStep == 1 &&
            endDate &&
            isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING)) &&
            !isCCLogin &&
            isProfileLoaded
        ) {
            refreshCart();
        }
        if (cart?.availableCartItems?.length > 0) {
            sendEventsForEcommercePageData(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATA,
                payloadEcommercePageData([], cart?.availableCartItems?.length)
            );
        }
    }, [cart?.availableCartItems?.length, isProfileLoaded]);

    useEffect(() => {
        setCurrentOffSet(currentOffset);
    }, [currentOffset]);

    const isCheckoutPageLoaded = () => {
        if (isProfileLoaded && cart?.id) {
            return true;
        } else {
            return false;
        }
    };

    const triggerCheckoutEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'triggerCheckoutEvents');
        }
    };

    const callEvents = () => {
        if (isStepForward) {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_COMPLETED,
                payloadEcommerceActionAnalytics(),
                checkoutStepPayload(formStep, currentStep, 1)
            );
        }
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_VIEWED,
            payloadEcommerceActionAnalytics(),
            checkoutStepPayload(formStep, currentStep, 0)
        );
    };

    const changeCurrentStep = stepToSet => {
        setIsStepForward(stepToSet > currentStep);
        setCurrentStep(stepToSet);
        triggerCheckoutEvents();
    };

    const showQuoteSuccessfullUi = () => {
        if (authorityType === AUTHORITY_TYPE.P2P && selectedTransmitOrSave !== TRANSMIT_NOW) {
            if (transmittedQuoteId || sucessfullyTransmittedQuoteId) {
                return true;
            }
        }
        return false;
    }

    const renderSteps = () => {
        if (userType == USER_TYPE.GUEST) {
            return (
                <Suspense
                    fallback={
                        <>
                            <Fallback />
                        </>
                    }>
                    <LazyCheckoutGuestUser
                        currentStep={currentStep}
                        setCurrentStep={changeCurrentStep}
                        currentOffSet={currentOffSet}
                        formStep={formStep}
                        isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                    />
                </Suspense>
            );
        } else if (userType == USER_TYPE.CREDIT) {
            return (
                <Suspense fallback={<Fallback />}>
                    <LazyCheckoutCreditUser
                        currentStep={currentStep}
                        setCurrentStep={changeCurrentStep}
                        currentOffSet={currentOffSet}
                        isCustomAccount={isCustomAccount}
                        customBillingFields={customBillingFields}
                        formStep={formStep}
                        isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                        multipleErrors={multipleErrors}
                        setMultipleErrors={setMultipleErrors}
                    />
                </Suspense>
            );
        } else {
            return (
                <LazyCheckoutCashUser
                    currentStep={currentStep}
                    setCurrentStep={changeCurrentStep}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                    isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                />
            );
        }
    };

    /** Below is UI rendering */

    // keeping this condition at top to avoid any other UI to load in checkout page
    if (showQuoteSuccessfullUi()) {
        // if create-quote is successful
        return (
            <Suspense fallback={<Fallback />}>
                <LazyQuoteConfirmation
                    isTransmit={selectedTransmitOrSave === TRANSMIT_NOW || sucessfullyTransmittedQuoteId}
                    confirmationType={SUCCESS}
                    transmittedQuoteId={transmittedQuoteId || sucessfullyTransmittedQuoteId}
                    salesforceQuoteId={salesforceQuoteId}
                />
            </Suspense>
        );
    } else if (submitReservation?.isSubmitReservationScreenVisible) {
        return (
            <Suspense fallback={<Fallback />}>
                <LazyReservationConfirmation />
            </Suspense>
        );
    } else {
        return (
            <div className='checkout-container'>
                {!isCheckoutLoading && (
                    <CheckoutStepsAndOrderSummary
                        cartItems={cartItems}
                        isRentalDatesEmpty={!endDate}
                        orderEstimates={orderEstimates}
                        renderSteps={renderSteps}
                        setCurrentStep={setCurrentStep}
                        formStep={formStep}
                        currentStep={currentStep}
                        multipleErrors={multipleErrors}
                    />
                )}
                {mediaType !== 'DESKTOP' && currentStep === formStep.SUBMIT_RESERVATION && (
                    <Suspense fallback={<Fallback />}>
                        <LazySubmitReservationButton
                            customClass={'submitCTASmallScreen'}
                            currentOffSet={currentOffSet}
                            handleStepChange={setCurrentStep}
                            currentStep={currentStep}
                        />
                    </Suspense>
                )}
                {mediaType !== 'DESKTOP' &&
                    punchoutUserData?.isPunchoutUser &&
                    currentStep === formStep.TRANSFER_ORDER && (
                        <Suspense fallback={<Fallback />}>
                            <LazyPunchoutOrderConfirmation
                                customClass={'submitCTASmallScreen'}
                                currentOffSet={currentOffSet}
                                handleStepChange={setCurrentStep}
                                currentStep={currentStep}
                            />
                        </Suspense>
                    )}
                {mediaType !== 'DESKTOP' &&
                    currentStep === formStep.TRANSMIT_OR_SAVE &&
                    authorityType === AUTHORITY_TYPE.P2P && (
                        <TransmitOrSaveMain selectedTransmitOrSave={selectedTransmitOrSave} />
                    )}
                <QuoteTransmitModal isModalOpen={isTransmitting} />
            </div>
        );
    }
};

export default memo(CheckoutDotcom);
